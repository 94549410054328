<div
  class="tenant-panel d-flex flex-row justify-content-between align-items-center"
>
  <div
    class="logo tenant-logo"
    [style.backgroundImage]="logo$ | async"
  ></div>
  <div>
    <idv-language-selector *ngIf="showLanguageSelector"></idv-language-selector>
  </div>
</div>
