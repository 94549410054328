<form
  *ngIf="questionGroup && formGroup"
  class="form-group"
  (ngSubmit)="submitAnsweredQuestionGroup()"
  [formGroup]="formGroup"
>
  <div class="d-flex flex-column">
    <h4
      class="recent-bloodpressure-header"
      id="mrc_q_bloodpressure_heading"
    >
      {{ (questionGroup?.questions)[0].text }}
    </h4>
  </div>

  <div class="d-flex flex-column">
    <mat-checkbox
      class="custom-control-container checkbox-label no-information-checkbox"
      id="mrc_q_bloodpressure_available"
      formControlName="noValuesCheckbox"
    >
      {{ checkboxText }}
    </mat-checkbox>
  </div>

  <div class="d-flex flex-column">
    <!-- Recent BP -->
    <h5 id="mrc_q_bloodpressure_1_heading">
      {{ (questionGroup?.questions)[2].text }}
    </h5>
    <ng-container formArrayName="recentBloodpressureReadings">
      <div class="recent-bloodpressure">
        <div
          *ngFor="
            let item of formGroup.get('recentBloodpressureReadings').controls;
            let i = index
          "
          [formArrayName]="i"
        >
          <div class="row-bloodpressure">
            <!-- Input 1 -->
            <mat-form-field
              appearance="fill"
              class="form-field-s"
            >
              <input
                matInput
                mask="{{ mask }}"
                thousandSeparator="{{ thousandSeparator }}"
                decimalMarker="{{ decimalSeparator }}"
                [id]="'mrc_q_bloodpressure_1_' + i + '_sys'"
                formControlName="0"
                placeholder="{{
                  getLabel$('numeric-input.placeholders.bloodpressure-systolic')
                    | async
                }}"
              />
              <mat-error *ngIf="hasError(item.controls[0])">
                <idv-error-help-block
                  [errors]="
                    errorsOnField(item.controls[0]) || errorsOnField(item)
                  "
                >
                </idv-error-help-block>
              </mat-error>
            </mat-form-field>

            <div class="field-separator">/</div>

            <!-- Input 2 -->
            <mat-form-field
              appearance="fill"
              class="form-field-s"
            >
              <input
                matInput
                mask="{{ mask }}"
                thousandSeparator="{{ thousandSeparator }}"
                decimalMarker="{{ decimalSeparator }}"
                [id]="'mrc_q_bloodpressure_1_' + i + '_dia'"
                formControlName="1"
                placeholder="{{
                  getLabel$(
                    'numeric-input.placeholders.bloodpressure-diastolic'
                  ) | async
                }}"
              />
              <mat-error *ngIf="hasError(item.controls[1])">
                <idv-error-help-block
                  [errors]="
                    errorsOnField(item.controls[1]) || errorsOnField(item)
                  "
                >
                </idv-error-help-block>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Previous BP -->
    <div>
      <h5 id="mrc_q_bloodpressure_2_heading">
        {{ (questionGroup?.questions)[7].text }}
      </h5>
    </div>

    <ng-container formArrayName="previousBloodpressureReadings">
      <div class="past-bloodpressure">
        <div
          *ngFor="
            let item of formGroup.get('previousBloodpressureReadings').controls;
            let i = index
          "
          [formArrayName]="i"
        >
          <div class="row-bloodpressure">
            <!-- Input 1 -->
            <mat-form-field
              appearance="fill"
              class="form-field-s"
            >
              <input
                matInput
                mask="{{ mask }}"
                thousandSeparator="{{ thousandSeparator }}"
                decimalMarker="{{ decimalSeparator }}"
                [id]="'mrc_q_bloodpressure_2_' + i + '_sys'"
                formControlName="0"
                placeholder="{{
                  getLabel$('numeric-input.placeholders.bloodpressure-systolic')
                    | async
                }}"
              />
              <mat-error *ngIf="hasError(item.controls[0])">
                <idv-error-help-block
                  [errors]="
                    errorsOnField(item.controls[0]) || errorsOnField(item)
                  "
                >
                </idv-error-help-block>
              </mat-error>
            </mat-form-field>

            <div class="field-separator">/</div>

            <!-- Input 2 -->
            <mat-form-field
              appearance="fill"
              class="form-field-s"
            >
              <input
                matInput
                mask="{{ mask }}"
                thousandSeparator="{{ thousandSeparator }}"
                decimalMarker="{{ decimalSeparator }}"
                [id]="'mrc_q_bloodpressure_2_' + i + '_dia'"
                formControlName="1"
                placeholder="{{
                  getLabel$(
                    'numeric-input.placeholders.bloodpressure-diastolic'
                  ) | async
                }}"
              />
              <mat-error *ngIf="hasError(item.controls[1])">
                <idv-error-help-block
                  [errors]="
                    errorsOnField(item.controls[1]) || errorsOnField(item)
                  "
                >
                </idv-error-help-block>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="d-flex flex-column">
    <!-- Error if field is required -->
    <idv-error-help-block
      *ngIf="areAllErrorMessagesShown"
      [errors]="
        errorsOnField(formGroup.get('previousBloodpressureReadings')) ||
        errorsOnField(formGroup.get('recentBloodpressureReadings'))
      "
    ></idv-error-help-block>

    <!-- Button container -->
    <idv-questionnaire-previous-next
      *ngIf="!readonlyMode"
      class="mt-5"
      [backButtonRouterLink]="backButtonRouterLink"
      [canProceed]="!!formGroup"
    ></idv-questionnaire-previous-next>
  </div>
</form>
